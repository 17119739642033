<template>
  <div>
    <b-card
      title="User Information"
      class="position-static"
    >
      <!-- form -->
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <b-col md="4">
              <b-form-group
                label="First Name"
                label-for="first_name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="First Name"
                  rules="required"
                >
                  <b-form-input
                    v-model="userObject.form.name"
                    placeholder="First Name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                label="Last Name"
                label-for="last_name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Last Name"
                  rules="required"
                >
                  <b-form-input
                    v-model="userObject.form.last_name"
                    placeholder="Last Name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                label="Mobile"
                label-for="mobile"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Mobile"
                  rules="required"
                >
                  <b-form-input
                    v-model="userObject.form.mobile"
                    placeholder="Mobile"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                label="E-Mail"
                label-for="email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="E-Mail"
                  rules="required"
                >
                  <b-form-input
                    v-model="userObject.form.email"
                    placeholder="E-Mail"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group label="Select Role">
                <validation-provider
                  #default="{ errors }"
                  name="Roles"
                  rules="required"
                >
                  <v-select
                    v-model="userObject.form.role"
                    :options="rolesList"
                    label="name"
                    :state="errors.length > 0 ? false:null"
                    :reduce="name => name.name"
                    placeholder="Select Role"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                label="Password"
                label-for="password"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <b-form-input
                    v-model="userObject.form.password"
                    placeholder="Password"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>

      <b-button
        v-if="!isLoading"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        @click="createUserFunction"
      >
        <feather-icon
          icon="PlusIcon"
          class="mr-25"
        />
        <span>Create User</span>
      </b-button>
      <b-button
        v-else
        variant="primary"
        disabled
      >
        <b-spinner small />
        Loading Please wait...
      </b-button>
    </b-card>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {
  BCard, BCol, BForm, BFormGroup, BRow, BFormInput, BButton, BSpinner,
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { heightTransition } from '@core/mixins/ui/transition'
import { required } from '@validations'
// eslint-disable-next-line import/extensions
import ToastificationContent from '@core/components/toastification/ToastificationContent'

export default {
  name: 'AddCommission',
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormGroup,
    BFormInput,
    BForm,
    BCard,
    BRow,
    BCol,
    BButton,
    vSelect,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      userObject: {
        form: {
          userType: 4,
          approved: true,
        },
        url: 'admin/users/adminUsers',
        storeName: 'users',
      },
      isLoading: false,
      required,
    }
  },
  computed: {
    ...mapGetters({
      rolesList: 'roles/rolesList',
    }),
  },
  created() {
    this.getRolesFunction()
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    ...mapActions({
      add: 'add',
      get: 'get',
    }),
    createUserFunction() {
      this.isLoading = !this.isLoading
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.add(this.userObject).then(response => {
            this.isLoading = !this.isLoading
            this.$toast({
              component: ToastificationContent,
              position: 'bottom-right',
              props: {
                title: 'Success',
                icon: 'CheckIcon',
                variant: 'success',
                text: response.data.message,
              },
            })
            this.$router.replace({ name: 'adminUsers' })
          }).catch(error => {
            this.isLoading = !this.isLoading
            this.$toast({
              component: ToastificationContent,
              position: 'bottom-right',
              props: {
                title: 'Error',
                icon: 'XIcon',
                variant: 'danger',
                text: error,
              },
            })
          })
        }
      })
    },
    getRolesFunction() {
      this.get({
        storeName: 'roles',
        url: '/admin/settings/roles',
        commitUrl: 'roles/SET_ROLES',
      })
    },
  },
}
</script>
